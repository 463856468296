<!-- Suplidos -->

<template>
  <div class="exp_M_presSup" v-if="schema">
    <v-sheet :elevation="4">

      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">       
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="event_Header">          
        </base_Header>
      </div>

      <!-- Contenido -->     
      <div class="conflex">

        <!-- Grid -->
        <base_Fgrid 
          style="width:850px"
          :padre="stName" 
          :Entorno="Entorno" 
          show-expand
          @onEvent="event_Grid">         

          <!-- Slot botonera Top -->
          <template v-slot:top_btns="{ Sitem }">    
           
            <div style="padding:10px 0 0 10px;">
              <btramto                      
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
              </btramto>
            </div>
          </template>

          <!-- Slot campos del grid menos el de acciones -->
       

          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>

          <!-- Slot panel expansible -->
          <template v-slot:expansible_ctrls="{}">             
            <exp_M_presSup_XM
              :padre="stName"
              :accion="accion"
              :accionRow="rowSelected"
              :auxRecord="accionRow"
              :disparo="disparo"            
              @onEvent="event_expansible"
              @reload="reload">           
            </exp_M_presSup_XM>
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>      
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  const exp_M_presSup_XM = () => plugs.groute("exp_M_presSup_XM.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Fgrid, base_Header, btracombi, btramto, exp_M_presSup_XM },
    props: {
      padre: { type:String, default: ''},
      id: { type:[Number, String], default: 1},  
      accionRow: { type:Object, default: ()=> {}},
      auxRecord: { type:Object, default: ()=> {}},
      disparoFinder: { type:Boolean, default:false }, 
      componenteTipo: { type:String, default:'FC' },      
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return { 
        schema:null,             
        api:'exp_presSup_F',
        stName:'stFexp_presSup_F',
        disparo:false,
        accion:'0',
             
        headers: {},        
      };
    },

    methods: {
      ini_data() {      
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;
    
        // entorno
        this.Entorno.grid.autoload= true;        
        this.Entorno.header.header= true;
        this.Entorno.grid.row.expansible= true;
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.FMF));
        
        this.headers = {
          header:[
            { text: "Factura", value: "fra_doc" },
            { text: "Fecha", value: "fra_fh", filtro:"fecha" },
            { text: "Cuenta", value: "name_cta_id", width:'50%' },                    
            { text: "Importe", value: "tot", filtro:'num', arg:2 }            
          ]
        };

        this.Entorno.grid.headers = this.set_headers();      
      },


      ini_component() {
        this.$set(this.Entorno.header, 'titulo', this.auxRecord.control + " - Suplidos.  Fra: " + this.accionRow.fra_doc + " - " + this.accionRow.name_cta_id);
        this.get_records(this.Entorno.grid.autoload? 'ini' : ''); 

        //si expediente cerrado no nuevo        
        this.btnSet(1,{view:true, disabled: this.auxRecord.chkconfadmon=='1'});
      },

      //
      reload() {        
        this.ini_component();
        //this.$store.commit(this.stName + '/update_record', { opcion: 'guardar', record:evt.r, id: evt.r.id });
      },
    
    }
  };
</script>
